import { ref, computed } from 'vue'

export const collapsed = ref(true)
export const toggleSidebar = () => {
  collapsed.value = !collapsed.value
  
}

export const SIDEBAR_WIDTH = 256
export const HEADER_WIDTH = 256
export const SIDEBAR_WIDTH_COLLAPSED = 60
export const HEADER_WIDTH_COLLAPSED = 70
export const sidebarWidth = computed(
  () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`,
  () => `${collapsed.value ? HEADER_WIDTH_COLLAPSED : HEADER_WIDTH}px`
)

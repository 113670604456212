import { createStore } from "vuex";
import { auth } from "./auth/auth.module";

export default createStore({
  modules: {
    auth,
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {}
})
/*
const store = createStore({
  modules: {
    auth,
  },
});
*/


//export default store;

//const store = createStore({
//  modules: {
//    auth,
//  },
//});
/*
const store = createStore({
  state: {
    sidebarVisible: '',
   sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
     state.sidebarVisible = payload.value
  },
 },
  actions: {},
  modules: {auth},
})*/

//export default store;
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import setupInterceptors from './services/setupInterceptors'
import { createI18n } from 'vue-i18n'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import VueNumberFormat from 'vue-number-format'
import FormatFilters from './filters/formatFilters'
import DictionaryColors from './filters/dictionaryColors'
import tooltip from '../src/tooltip.js'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import ReadMore from 'vue-read-more';
import VueHighlightJS from 'vue3-highlightjs'
import SignalRHub from './services/signalRHub.service'
import VueSplide from '@splidejs/vue-splide';
import VueAwesomePaginate from "vue-awesome-paginate";


// import {
//   FaRegularTrashAlt,
// FcProcess,
// FaCheckCircle,
// FaRegularTimesCircle,
// } from 'oh-vue-icons/icons'
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/styles/_widgets-info.scss'
import '../src/styles/_header.scss'
import '../src/styles/_button.scss'
import '../src/styles/_carousel.scss'
import '../src/styles/_tooltip.scss'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import './assets/tailwind.css'
import 'highlight.js/styles/atom-one-dark.css'
import "vue-awesome-paginate/dist/style.css";

//import i18n from './i18n'

//Vue.prototype.$http = axios;
// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
/*
const messages = {
  en: {
    message: {
      client: 'clientxxxxx'
    }
  },
  ja: {
    message: {
      client: 'こんにちは、世界'
    }
  }
}*/

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getLanguage()
{
  /*console.log("Entrei no getLanguage");*/
   var local=localStorage?.getItem('userData');
   /*console.log("local="+local);*/
   if(local!=null || local!='undefined')
   {
      var userdata = JSON.parse(local);

     return userdata?.language == 'pt-PT' || userdata?.language == 'PT' ? 'pt-PT': (userdata?.language!= null && userdata?.language!='undefined' ? userdata.language.toLowerCase() : 'en');

   }
   else return 'en'
}


// 2. Create i18n instance with options
const i18n = createI18n({  
  locale: getLanguage(), // set locale
  fallbackLocale: getLanguage(), // set fallback locale
  messages: loadLocaleMessages(), // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

/*const synapseHub = new Vue() 
// every component will use this.$questionHub to access the event bus
Vue.prototype.$synapseHub = synapseHub
// Forward server side SignalR events through $questionHub, where components will listen to them
connection.on('SendNotificationUpdate', (notificationsList) => {
  console.log("SignalR notificationsList",notificationsList);
})*/

setupInterceptors(store)
// addIcons({
//   FaRegularTrashAlt, /* FcProcess, FaCheckCircle, FaRegularTimesCircle*/,
// })

// 3. Create a vue root instance
const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(i18n)
app.use(PerfectScrollbar)
app.use(VueTelInput)
app.directive('tooltip', tooltip)
app.use(ReadMore);
app.use(VueHighlightJS)
app.use(SignalRHub);
app.use( VueSplide );
app.use(VueAwesomePaginate );
app.use(VueNumberFormat, {
  prefix: '',
  suffix: '',
  decimal: ',',
  thousand: '.',
  precision: 1,
  acceptNegative: true,
  isInteger: false,
})
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)



app.config.globalProperties.$format = FormatFilters;
app.config.globalProperties.$dicColorsSegm = {'Loyal': '#7bdfff', 
                                          'Lost': '#e7e7e7',
                                          'At risk': '#f9e47d',
                                          'Promising': '#6abbff',
                                          'Needs attention': '#fbc66c',
                                          'Champions': '#9be89e',
                                          "Can't lose": '#fc94a4',
                                          'Recent customers': '#9b97f9',
                                          'Recent': '#9b97f9'};

app.config.globalProperties.$dicColorsChannels = {'Mobile': '#88bad3', 
                                                  'Web': '#fdc233',
                                                  'Offline': '#ced4da',
                                                  'Online ': '#fdc233',
                                                  'Not Specified' : '#9b97f9'};

app.config.globalProperties.$dicColorsCSAT = {'1': '#9be89e', 
                                                  '2': '#f9e47d',
                                                  '3': '#fbc66c',
                                                  '4' : '#9b97f9',
                                                  '5' : '#fc94a4'};

//console.log("process.env.VUE_APP_I18N_LOCALE="+process.env.VUE_APP_I18N_LOCALE) 
// 5. Mount
app.mount('#app')
app.config.globalProperties.$filters = {
  roundToTwoDecimalPlaces(value) {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    return value;
  },
};

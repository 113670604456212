<template>
  <div class="oi-app">
    <router-view />
  </div>
</template>

<script>
import { sidebarWidth } from '@/components/state'

export default {
  components: {},
  setup() {
    return { sidebarWidth }
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';

.oi-app {
  height: 100vh;
  overflow: scroll;
}
</style>

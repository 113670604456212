import axios from 'axios';
const API_URL = process.env.VUE_APP_OI360_BASE_AUTH;
//const API_URL = 'https://localhost:5401/';
class AuthService {
  login(user) {
    console.log("Login Login Login");
    localStorage.removeItem('user');
    localStorage.removeItem('userData');

    let params = new URLSearchParams()
    let config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    params.append('client_id', 'resourceOwner')
    params.append('username', user.username)
    params.append('password', user.password)
    params.append('client_secret', 'secret')
    params.append('grant_type', 'password')
    
    return axios
      .post(API_URL + 'connect/token', params, config)
      .then(response => {
        if (response.data.access_token) {
          
          localStorage.setItem('user', JSON.stringify(response.data));

          //console.log("user in login",JSON.stringify(response.data))
          //localStorage.setItem('userData', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('userData');
  }
  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}
export default new AuthService();